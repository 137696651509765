<template>
  <div style="margin: 15px">
    <v-card hover ripple @click="openTrack">
      <v-img
        :src="image_path"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-card-title v-text="name"></v-card-title>
        <v-card-subtitle
          ><span v-html="getTrackIcon(track_type)"></span> |
          {{ convertDate(start_time) }} | {{ formatted_distance }}
          {{ heartrate != 0 ? "| " : "" }}
          <i class="fad fa-heart-rate" v-if="heartrate != 0"></i>
          {{ heartrate != 0 ? Math.round(heartrate) + " bpm" : "" }}</v-card-subtitle
        >
      </v-img>
    </v-card>
  </div>
</template>

<script>
import track_types from "../../assets/data/types";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    image_path: {
      type: String,
      required: false,
    },
    distance: {
      type: String,
      required: false,
    },
    heartrate: {
      type: Number,
      required: false,
    },
    start_time: {
      type: Number,
      required: true,
    },
    track_type: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    formatted_distance() {
      if (this.distance >= 1000) {
        return Math.round((this.distance / 1000) * 10) / 10 + " km";
      } else {
        return Math.round(this.distance * 10) / 10 + " m";
      }
    },
  },
  data() {
    return {
      track: null,
    };
  },
  mounted() {},
  methods: {
    convertDate(datetime) {
      datetime = new Date(datetime);
      if (!(datetime instanceof Date && !isNaN(datetime))) {
        return this.$t("general.no_date_defined");
      }
      var months = [
        this.$t("general.month.january"),
        this.$t("general.month.february"),
        this.$t("general.month.march"),
        this.$t("general.month.april"),
        this.$t("general.month.may"),
        this.$t("general.month.june"),
        this.$t("general.month.july"),
        this.$t("general.month.august"),
        this.$t("general.month.september"),
        this.$t("general.month.october"),
        this.$t("general.month.november"),
        this.$t("general.month.december"),
      ];

      return (
        datetime.getDate() +
        ". " +
        months[datetime.getMonth()] +
        " " +
        datetime.getFullYear()
      );
    },
    getTrackIcon(number) {
      if (Number.isNaN(number)) {
        return "-";
      } else {
        return (
          "<i class='fad " + track_types.getTrackType(number).icon + "'></i>"
        );
      }
    },
    openTrack() {
      this.$router.push("/track/" + this.id);
    },
  },
};
</script>

<style>
</style>