<template>
  <div>
    <v-text-field
      :loading="loading"
      solo
      :placeholder="$t('tracks.search')"
      style="margin:10px 10px 0 10px;"
      v-model="query"
    ></v-text-field>
    <v-layout>
      <v-row sm6>
        <v-select style="margin:0 0 0 10px;max-width:30%;" rounded v-model="filter_track_type" :items="track_types">
          <template v-slot:item="{item}">
            <i class="fad" :class="item.icon" style="margin-right:5px;"></i>
            <span>{{ $t(item.name) }}</span>
          </template>
          <template v-slot:selection="{item}">
            <i class="fad" :class="item.icon" style="margin-right:5px;"></i>
            <span>{{ $t(item.name) }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row sm6></v-row>
    </v-layout>
    <v-layout v-if="filter_active" style="margin:0;" wrap row>
      <v-flex sm6 xs12 md4 lg3 v-for="track in results" :key="track.id">
        <TrackCard
          :id="track.id"
          :name="track.data.name"
          :type="track.data.type"
          :image_path="track.data.thumb_path"
          :distance="track.data.calc_distance"
          :start_time="parseInt(track.data.start_time)"
          :track_type="parseInt(track.data.track_type)"
          :data="track.data.route"
        ></TrackCard>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { storage } from "../../schmucklicloud";
import TrackCard from "./TrackCard";

import track_types from "../../assets/data/types";

export default {
  data() {
    return {
      query: "",

      track_types: track_types.types,
      filter_track_type: 0,

      filter_active: false,

      results: [],
      loading: false
    };
  },
  mounted() {
    //Adds the all parameter
    if (this.track_types[0].value !== 0) {
      this.track_types.unshift({ name: "All", icon: "fa-list", value: 0 });
    }
  },
  methods: {
    async search(query) {
      this.loading = true;

      var filters;
      if (this.filter_track_type === 0) {
        filters = [
          {
            column: "name",
            operator: "contains_i",
            value: query
          }
        ];
      } else {
        filters = [
          {
            column: "name",
            operator: "contains_i",
            value: query
          },
          {
            column: "track_type",
            operator: "==",
            value: this.filter_track_type
          }
        ]
      }

      var response = await storage.get(
        "tracks",
        filters,
        [
          {
            column: "start_time",
            order: "desc"
          }
        ],
        undefined,
        10,
        ["route"]
      );
      if (response.isOK && response.data) {
        this.results = response.data;
      } else {
        this.results = [];
      }

      this.filter_active = !(this.query === "" && this.filter_track_type === 0);
      this.$emit("resultschanged", this.filter_active);

      this.loading = false;
    }
  },
  watch: {
    query(q) {
      this.search(q);
    },
    filter_track_type() {
      this.search(this.query);
    }
  },
  components: {
    TrackCard
  }
};
</script>

<style>
</style>