<template>
  <div>
    <v-layout style="margin:0;" row>
      <v-flex xs6>
        <h1>{{ $t("tracks.header") }}</h1>
      </v-flex>
      <v-flex style="text-align:right;" xs6>
        <v-btn @click="d_import = true">
          <i style="margin-right:5px;" class="fal fa-plus"></i>
          {{ $t("tracks.import") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <TrackSearch @resultschanged="onResultsChanged"></TrackSearch>
    <div v-if="loaded && !filters_active">
      <v-layout style="margin:0;" wrap row>
        <v-flex sm6 xs12 md4 lg3 v-for="track in tracks" :key="track.id">
          <TrackCard
            :id="track.id"
            :name="track.data.name"
            :type="track.data.type"
            :image_path="track.data.thumb_path"
            :distance="track.data.calc_distance"
            :heartrate="parseFloat(track.data.calc_heartrate)"
            :start_time="parseInt(track.data.start_time)"
            :track_type="parseInt(track.data.track_type)"
            :data="track.data.route"
          ></TrackCard>
        </v-flex>
      </v-layout>
      <v-card v-intersect="infiniteScrolling"></v-card>
    </div>
    <div v-else-if="!filters_active" style="width:100%;text-align:center;padding-top:200px;">
      <v-progress-circular :indeterminate="true" size="50"></v-progress-circular>
    </div>
    <v-dialog max-width="400" v-model="d_import">
      <ImportCard @importFinished="loadNew" @closeDialog="d_import = false"></ImportCard>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";

import TrackCard from "../components/tracks/TrackCard";
import ImportCard from "../components/tracks/ImportCard";
import TrackSearch from "../components/tracks/TrackSearch";

export default {
  data() {
    return {
      loaded: false,
      tracks: [],

      filters_active: false,

      last_tracks_length: 0,

      d_import: false
    };
  },
  mounted() {
    this.load(0, 15);
  },
  methods: {
    infiniteScrolling() {
      if (this.last_tracks_length === this.tracks.length) {
        return;
      } else {
        this.last_tracks_length = this.tracks.length;
        setTimeout(
          function() {
            this.load(this.tracks.length, 15);
          }.bind(this),
          1000
        );
      }
    },
    onResultsChanged(filters_active) {
      this.filters_active = filters_active;
    },
    loadNew() {
      this.load(true);
    },
    async load(start, limit) {
      if (start === true) {
        this.tracks = [];
        start = 0;
        limit = 15;
      }
      this.d_import = false;

      var response = await storage.getAll(
        "tracks",
        [
          {
            column: "start_time",
            order: "desc"
          }
        ],
        start,
        limit,
        ["route"]
      );
      if (response.isOK) {
        if (response.data !== undefined) {
          this.tracks = this.tracks.concat(response.data);
        }
      }
      this.loaded = true;
    }
  },
  components: {
    TrackCard,
    ImportCard,
    TrackSearch
  }
};
</script>

<style>
</style>