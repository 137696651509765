<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text>
      <v-file-input v-model="upload_data" @change="startImport"></v-file-input>
      <div v-if="imported">
        <v-text-field v-model="name" :label="$t('tracks.name')"></v-text-field>
        <table style="width:100%;">
          <tr>
            <td>Type:</td>
            <td>{{ this.type.toUpperCase() }}</td>
          </tr>
        </table>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="saveData" color="primary">{{ $t("tracks.import") }}</v-btn>
      <v-btn text @click="closeDialog" color="default">{{ $t("general.close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { storage } from "../../schmucklicloud";
import { Notifications } from "../../notification";

import gpxParser from "../../plugins/gpx-parser";
import { parseTCXString } from "../../plugins/tcx-parser";

export default {
  data() {
    return {
      upload_data: null,
      import_data: null,

      imported: false,
      name: "",
      type: null,
      track: null
    };
  },
  methods: {
    startImport() {
      this.imported = false;
      if (this.upload_data !== undefined) {
        //Get file type
        var file_name = this.upload_data.name.split(".");
        this.type = file_name[file_name.length - 1];

        var reader = new FileReader();
        reader.readAsText(this.upload_data);
        reader.onload = function() {
          this.import_data = reader.result;
          this.parseData();
        }.bind(this);
      }
    },
    parseData() {
      switch (this.type) {
        case "gpx":
          var gpx = new gpxParser();
          gpx.parse(this.import_data);
          this.track = gpx.tracks[0];
          this.name = this.track.name || gpx.metadata.name;
          break;
        case "tcx":
          parseTCXString(
            this.import_data,
            function(status, result) {
              this.track = result;
              this.name = this.track.title;
            }.bind(this)
          );
          break;
        default:
          this.resetView();
          Notifications.show(this.$t("tracks.notification.file_not_supported"));
          break;
      }
      this.imported = true;
    },
    async saveData() {
      var response = await storage.insert("tracks", {
        name: this.name,
        route: (this.import_data).replace(/\+/g, "%2B"), //Otherwise it would not save the plus sign
        type: this.type
      });

      if (response.isOK) {
        Notifications.show(
          this.$t("tracks.notification.track_has_been_imported")
        );
        this.$emit("importFinished");
        this.resetView();
        this.$router.push("/track/" + response.data.id);
      } else {
        Notifications.show(response.message);
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    resetView() {
      setTimeout(
        function() {
          this.import_data = null;
          this.upload_data = null;
          this.imported = false;
        }.bind(this),
        200
      );
    }
  }
};
</script>

<style>
</style>